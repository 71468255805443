<template>
  <div
    class="relative inline-block text-left"
    @mouseover="isMenuOpen = true"
    @mouseleave="isMenuOpen = false"
    @click="isMenuOpen = !isMenuOpen"
  >
    <button
      id="options-menu"
      type="button"
      class="flex flex-row items-center justify-between w-full px-4 py-5 text-base leading-5 font-medium text-white hover:bg-gray-darkest transition ease-in-out duration-150"
      :class="{ 'hover:bg-red': dark}"
      aria-haspopup="true"
      aria-expanded="true"
      @click="isMenuOpen = !isMenuOpen"
    >
      <img
        v-if="icon"
        class="mr-2 h-[20px] w-[20px]"
        :src="icon"
        alt=""
      >
      <span class="inline-block w-full text-left lg:text-center">
        {{ label }}
      </span>
      <img
        src="/img/menu_lenyilo.svg"
        class="ml-2 transition ease-in-out duration-150 w-[12px] h-[6px]"
        width="12"
        :class="{'transform rotate-180':isMenuOpen}"
      >
    </button>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="isMenuOpen"
        class="origin-top-right absolute right-0  w-56 shadow-lg text-base overflow-hidden z-50"
      >
        <div
          class="bg-red text-white shadow-xs"
          :class="{ 'bg-gray-darkest': dark}"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <span 
            v-for="(item,index) in menu"
            :key="index"
          >
            <nuxt-link
              v-if="item.url"
              :to="item.url"
              class="p-4 flex items-center space-x-2 border-b border-red-lightest hover:bg-gray-darkest last:border-b-0"
              :class="{ 'hover:bg-red': dark}"
            >
              <span> {{ item.name }} </span>
            </nuxt-link>
            <a
              v-else
              class="cursor-pointer p-4 flex items-center space-x-2 border-b border-red-lightest hover:bg-gray-darkest last:border-b-0"
              :class="{ 'hover:bg-red': dark}"
              @click="item.click()"
            >
              <span> {{ item.name }} </span>
            </a>
          </span>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>

export default ({
    props:{
        label:{
            type:String, 
            required: true
        },
        icon:{
            type:String, 
            required: false
        },
        menu:{
            type:Array,
            required: true
        },
        dark: {
          type: Boolean,
          default: false
        }
    },
  data () {
    return {
      isMenuOpen: false
    };
  },
})
</script>
