<template>
  <client-only>
    <section class="">
      <!-- admin -->
      <div 
        v-if="loggedIn() && isAdmin()"
        class="bg-gray-darkest"
      >
        <div class="w-full mx-auto">
          <nav class="flex flex-wrap items-center justify-center px-8">
            <div class="toggle hidden lg:flex flex-col lg:flex-row w-full lg:w-auto text-right text-bold mt-5 lg:mt-0 ">        
              <div
                v-for="(item,index) in adminMenu"
                :key="'admin_' + index"
              >
                <router-link
                  v-if="item.url"
                  :to="item.url"
                  class="block lg:inline-block px-4 py-5 text-base leading-5 font-medium text-white hover:bg-red"
                >
                  <img
                    v-if="item.icon"
                    class="mr-2"
                    :src="icon"
                    width="20"
                    alt=""
                  >
                  {{ item.name }}
                </router-link>
                <dropdown
                  v-if="item.child"
                  :label="item.name"
                  :icon="item.icon?item.icon:null"
                  :menu="item.child"
                  dark
                />
              </div>
            </div>
          </nav>
        </div>
      </div>
      <!-- admin end -->
      <!-- guest -->
      <div class="bg-red ">
        <div class="w-full overflow-x-hidden lg:overflow-x-visible max-w-7xl md:max-w-lg lg:max-w-6xl 2xl:max-w-7xl mx-auto">
          <nav
            class="flex flex-col lg:flex-row  overflow-x-hidden lg:overflow-x-visible flex-shrink-0 items-center justify-between px-8 gap-3"
            :class="{ 'relative': !showNav}"
          >
            <div
              class="flex flex-row justify-between w-full lg:w-auto place-items-center"
              :class="{ 'md:sm:relative': !showNav}"
            >
              <!-- logo -->
              <router-link
                to="/"
                class="w-3/4 lg:w-full"
              >
                <img
                  src="/img/folkradio_logo.svg"
                  alt="Folkrádio"
                  width="135"
                >
              </router-link>
              <!-- hamburger -->
              <div class="lg:hidden">
                <button
                  id="hamburger"
                  class="py-3 outline-none"
                  @click="showNav = !showNav"
                >
                  <img
                    :class="{ 'hidden': showNav}"
                    src="/img/burger_menu_icon.svg"
                    width="40"
                    height="40"
                    class="h-[25px] w-[25px]"
                  >
                  <img
                    :class="{ 'hidden': !showNav}"
                    src="/img/close_icon.svg"
                    width="40"
                    height="40"
                    class="h-[25px] w-[25px]"
                  >
                </button>
              </div>
            </div>
            <!-- menu -->
           
            <div
              class="toggle overflow-y-scroll lg:overflow-y-visible absolute bg-red z-20 w-48 top-[48px] right-0 lg:top-auto lg:relative lg:right-auto flex-grow lg:flex flex-col h-screen lg:h-auto lg:flex-row lg:w-full lg:text-right text-bold lg:mt-0 justify-end transition-all  duration-300 ease-in-out"
              :class="{ 'right-[-500px] lg:flex': !showNav}"
            >        
              <div
                v-for="(item,index) in guestMenu"
                :key="'guest_' + index"
                class="hidden lg:flex"
              >
                <router-link
                  v-if="item.url"
                  :to="item.url"
                  class="block lg:inline-block px-4 py-5 text-base leading-5 font-medium text-white hover:bg-gray-darkest"
                >
                  <img
                    v-if="item.icon"
                    class="mr-2"
                    :src="icon"
                    width="20"
                    alt=""
                  >
                  {{ item.name }}
                </router-link>
                <dropdown
                  v-if="item.child"
                  :label="item.name"
                  :icon="item.icon?item.icon:null"
                  :menu="item.child"
                />
              </div>
              <div
                v-for="(item,index) in guestMenuOnMobile"
                :key="'guest2_' + index"
                class="lg:hidden"
              >
                <router-link
                  v-if="item.url"
                  :to="item.url"
                  class="block lg:inline-block px-4 py-5 text-base leading-5 font-medium text-white hover:bg-gray-darkest"
                >
                  <img
                    v-if="item.icon"
                    class="mr-2"
                    :src="icon"
                    width="20"
                    alt=""
                  >
                  {{ item.name }}
                </router-link>
              </div>
             
              <div
                v-if="!loggedIn()"
              >
                <router-link 
                  to="/belepes"
                  class="flex px-4 py-5 text-base leading-5 font-medium text-white hover:bg-gray-darkest"
                >
                  <img
                    class="mr-2 h-[20px] w-[20px]"
                    src="/img/profile_icon.svg"
                    alt=""
                  >
                  Belépés
                </router-link>
              </div>
              <!-- <div v-if="loggedIn()"> -->
              <div
                v-for="(item,index) in userMenu"
                :key="'user_' + index"
                :class="{ 'hidden': !loggedIn() }"
              >
                <dropdown     
                  :label="item.name"
                  :icon="item.icon?item.icon:null"
                  :menu="item.child"
                  class="hidden lg:inline-block"
                />
                <div
                  v-for="(child, index2) in item.child"
                  :key="index2"
                  class="block lg:hidden"
                >
                  <router-link
                    v-if="child.url"
                    :to="child.url? child.url:''"
                    class="block px-4 py-5 text-base leading-5 font-medium text-white hover:bg-gray-darkest"
                  >
                    {{ child.name }}
                  </router-link>
                </div>
              </div>
              <div v-if="loggedIn()">
                <a
                  :class="{ 'hidden': !loggedIn() }"
                  class="lg:hidden cursor-pointer block px-4 py-5 text-base leading-5 font-medium text-white hover:bg-gray-darkest"
                  @click.prevent="logout()"
                >Kijelentkezés</a>
              </div>
              <!-- <a
                :class="{ 'hidden': !loggedIn() }"
                class="px-4 py-5 text-base leading-5 font-medium text-white hover:bg-gray-darkest cursor-pointer"
                @click.prevent="logout()"
              >Kijelentkezés</a>
              </div>
            </div> -->
            </div>
          </nav>
        </div>
      </div>
      <div
        class="bg-gray bg-opacity-80 h-screen w-screen absolute z-10"
        :class="{ 'hidden': !showNav}"
      />
      <!-- guest end -->
    </section>
  </client-only>
</template>
<script>
const Cookie = process.client ? require("js-cookie") : undefined;
import dropdown from "~/components/dropdown.vue";
export default {
  components: {
    dropdown
  },
  data() {
    return {
      showNav: false,
      showNavAdminbar: false,
      adminMenu:[
        {
          name:'Beállítások',
          url:'/admin/beallitasok',
          child:null
        },
        {
          name:'Oldalak',
          url:'/admin/oldalak',
          child:null
        },
        {
          name:'Események',
          url:null,
          child:[
            {
              name:'Összes esemény',
              url:'/admin/esemenyek',
            },
             {
              name:'Eseménylisták',
              url:'/admin/esemenyek/listak'
             }
          ]
        },
        {
          name:'Galéria',
          url:'/admin/albumok',
          child:null
        },
        {
          name:'Folkszemle',
          url:null,
          child:[
            {
              name:'Új cikk',
              url:'/admin/folkszemle/cikk',
            },
            {
              name:'Összes cikk',
              url:'/admin/folkszemle'
            },
            {
              name:'Új idézet',
              url:'/admin/folkszemle/idezetgyujtemeny/idezet',
            },
            {
              name:'Összes idézet',
              url:'/admin/folkszemle/idezetgyujtemeny'
            },
            {
              name:'Új kérdés',
              url:'/admin/folkszemle/tudasproba/kerdes',
            },
            {
              name:'Összes kérdés',
              url:'/admin/folkszemle/tudasproba'
            }
          ]
        },
        {
          name:'Tallózó',
          url:null,
          child:[
            {
              name:'Új cikk',
              url:'/admin/tallozo/cikk',
            },
            {
              name:'Összes cikk',
              url:'/admin/tallozo'
            }
          ]
        },
        {
          name:'Fórum',
          url:'/admin/forum',
          child: null
        },
        {
          name:'Felhasználók',
          url:'/admin/felhasznalok',
          child: null
        },
        {
          name:'Erőforrások',
          url:null,
          child:[
            {
              name:'Előadásmódok',
              url:'/admin/eroforrasok/eloadasmodok',
            },
            {
              name:'Etnikumok',
              url:'/admin/eroforrasok/etnikumok',
            },
            {
              name:'Források',
              url:'/admin/eroforrasok/forrasok'
            },
            {
              name:'Gyűjtők',
              url:'/admin/eroforrasok/gyujtok'
            },
            {
              name:'Játszhatóság',
              url:'/admin/eroforrasok/jatszhatosag'
            },
            {
              name:'Kiadók',
              url:'/admin/eroforrasok/kiadok'
            },
            {
              name:'Megyék',
              url:'/admin/eroforrasok/megyek'
            },
            {
              name:'Műfajok',
              url:'/admin/eroforrasok/mufajok'
            },
            {
              name:'Szövegforrások',
              url:'/admin/eroforrasok/szovegforrasok'
            },
            {
              name:'Tájegységek',
              url:'/admin/eroforrasok/tajegysegek'
            },
            {
              name:'Települések',
              url:'/admin/eroforrasok/telepulesek'
            },
            {
              name:'Témák',
              url:'/admin/eroforrasok/temak'
            }
          ]
        },
        {
          name:'Médiatár',
          url:null,
          child:[
            {
              name:'Dalszövegek',
              url:'/admin/dalszovegek',
            },
             {
              name:'Albumok',
              url:'/admin/zeneialbumok',
             },
             {
              name:'Felvételek',
              url:'/admin/felvetelek',
            },{
              name:'Előadók',
              url:'/admin/eloadok',
            }
          ]
        },
        {
          name:'Rádió',
          url:'/admin/radio',
          child: null
        },
        {
          name:'Támogatások',
          url:'/admin/tamogatasok',
          child: null
        },
      ],
      guestMenu:[
        {
          name:'Folknaptár',
          url:'/folknaptar',
          child:null
        },
        {
          name:'Tallózó',
          url:'/tallozo',
          child:null
        },
        {
          name:'Dalszövegek',
          url:'/mediatar/dalszovegek',
          child: null
        },
        {
          name:'Galéria',
          url:'/galeria',
          child:null
        },
         {
          name:'Folkszemle',
          url:null,
          child:[
            {
              name:'Cikkek',
              url:'/folkszemle',
            },
             {
              name:'Idézetgyűjtemény',
              url:'/folkszemle/idezetgyujtemeny'
             },
             {
              name:'Tudáspróba',
              url:'/folkszemle/tudasproba?game=' + this.$unique(),
            }
          ]
        },
        {
          name:'Médiatár',
          url:null,
          child:[
            {
              name:'Albumok',
              url:'/mediatar/albumok',
            },
             {
              name:'Felvételek',
              url:'/mediatar/felvetelek'
             }
          ]
        },
        {
          name:'Fórum',
          url:'/forum',
          child:null
        },
      ],
       guestMenuOnMobile:[
        {
          name:'Folknaptár',
          url:'/folknaptar/naptar'
        },
        {
          name:'Tallózó',
          url:'/tallozo'
        },
        {
          name:'Dalszövegek',
          url:'/mediatar/dalszovegek',
        },
        {
          name:'Galéria',
          url:'/galeria'
        },
         {
          name:'Folkszemle',
          url:'/folkszemle',
          
        },
         {
              name:'Idézetgyűjtemény',
              url:'/folkszemle/idezetgyujtemeny'
             },
             {
              name:'Tudáspróba',
              url:'/folkszemle/tudasproba?game=' + this.$unique(),
            },
             {
              name:'Műsorújság',
              url:'radio/musorujsag'
            },
             {
              name:'Albumok',
              url:'/mediatar/albumok',
            },
              {
              name:'Felvételek',
              url:'/mediatar/felvetelek'
             },
             {
          name:'Fórum',
          url:'/forum'
        }
        
      ],
      userMenu:[
        {
          name:'Fiókom',
          url:null,
          icon:'/img/profile_icon.svg',
          child:[
            {
              name:'Profilom',
              url:'/fiokom/profilom',
            },
             {
              name:'Új esemény felvitele',
              url:'/fiokom/esemeny'
             },
             {
              name:'Saját eseményeim',
              url:'/fiokom/esemenyeim',
            },
             {
              name:'Új album felvitele',
              url:'/fiokom/album',
            },
            {
              name:'Saját albumaim',
              url:'/fiokom/albumaim',
            },
            {
              name: 'Kijelentkezés',
              click: this.logout
            }
          ]
        },
      ],
    };
  },
  watch: {
    $route() {
      this.showNav = false;
      this.showNavAdminbar = false;
    }
  },
  methods: {
    openRadio() {
      window.open(
        "http://stream.folkradio.hu/folkradio.mp3",
        "",
        "scrollbars=no,menubar=no,left=100,top=300,height=110,width=620,resizable=yes,toolbar=no,location=no,status=no"
      );
    },
    setShowNav(state) {
      this.showNav = state;
    },

    logout() {

      this.$rxt.toast("Sikeres kijelentkezés, legyen szép napod!", {
        title: "Sikeres művelet",
        autoHideDelay: 3500,
        variant: "success"
      });

      if(this.$store.state.auth.provider === "facebook") {
        window.FB.logout(function () {
          console.log('Facebook logout success')
        });
      }
      Cookie.remove("__session");
      this.$store.commit("auth/setProvider", 'email');
      this.$store.commit("auth/setToken", null);
      this.$store.commit("auth/setUser", null);
      this.$router.replace("/");
    },
  }
};
</script>